import React from 'react';
import Player from 'react-player/youtube';
import {
  AspectRatio,
  Button,
  Center,
  Container,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { graphql, Link, navigate } from 'gatsby';
import { useLocation, WindowLocation } from '@reach/router';
import { noop } from 'lodash/fp';

import Deindex from '../../components/Deindex';

const TITLE = `3. Recap key concepts`;
const CAPTION = `You're well on your way to becoming a UX/UI designer!`;
const SUMMARY = `
  Now that you have had a small taste of the stages and decisions a UX/UI designer faces in the industry,
  are you ready to delve deeper? We're ready to partner with you to support you in this new career pathway.
  Click the finished button and take the next small step forward!
`;
const BACK_CTA_LABEL = 'Back to play';
const FORWARD_CTA_LABEL = `I'm done playing`;

const VIDEO_SRC = 'https://youtu.be/x7lTJxnlo6s';

const Page = (): React.ReactElement => {
  const { state } = useLocation() as WindowLocation<{
    lead: { email: string };
  }>;

  const handleActivityComplete = (): void => {
    const payload = { email: state?.lead?.email };

    const baseUrl = process.env.IRONHACK_API?.slice(0, -8) as string;

    fetch(`${baseUrl}/formHandlerSubmission/shortCourseUxComplete`, {
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          throw new Error('Could not complete application');
        }

        return navigate(`/en/ux-shortcourse-next-cohorts`, {
          state: { email: state?.lead?.email },
        });
      })
      .catch(noop);
  };

  return (
    <Center flexDir="column" height="100vh" maxW="1440px" mx="auto" p={3}>
      <Deindex />
      <Container as={Stack} maxW="520px" p={0} spacing={4} textAlign="center">
        <Text textStyle="title1">{TITLE}</Text>
        <AspectRatio ratio={16 / 9}>
          <Player controls height="100%" url={VIDEO_SRC} width="100%" />
        </AspectRatio>
        <Text fontWeight="bold" textStyle="body4">
          {CAPTION}
        </Text>
        <Text textStyle="body5">{SUMMARY}</Text>
      </Container>
      <Stack direction={['column', null, 'row']} mt={4} spacing={2} w="100%">
        <Button
          as={Link}
          leftIcon={<ArrowBackIcon />}
          state={{ lead: state?.lead }}
          sx={{ w: ['100%', null, 'auto'] }}
          to={'/en/ux-shortcourse-step-2'}
          variant="outline"
        >
          {BACK_CTA_LABEL}
        </Button>
        <Spacer />
        <Button
          onClick={handleActivityComplete}
          rightIcon={<ArrowForwardIcon />}
          w={['100%', null, 'auto']}
        >
          {FORWARD_CTA_LABEL}
        </Button>
      </Stack>
    </Center>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: { lng: { eq: "en" }, ns: { regex: "/(seo)/" } }
    ) {
      ...TranslationFragment
    }
  }
`;

export default Page;
